h1 {
  text-align: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.cont-footer-princ {
  left: 30px !important;
}
.titulocasos {
  font-size: 20px !important;
  color: #1b538a;
}
.titulocasosx {
  font-size: 20px !important;
  color: white;
}
.titulocasos1 {
  font-size: 20px !important;
  color: #1814d6;
}
.titulocasosHx {
  font-size: 20px;
  color: #1814d6;
  margin-top: 20px;
}

.contenedor-cxc {
  width: 100%;
  height: 550px;
  background-color: #d2ddeb;
}

.contenedor-titulocxc {
  display: contents;
  height: 200px;
}

.titulo-cxc1 {
  line-height: 15px;
  background-color: #1b538a;
  font-size: 15px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 0px;
}

.titulo-cxc2 {
  line-height: 0px;
  background-color: #1b538a;
  font-size: 15px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
}
.titulo-reprecxc {
  margin-left: 20px;
  line-height: 10px;
  font-size: 15px;
  text-align: center;
  color: #1b538a;
  font-weight: bold;
}

.labelcedulac {
  font-size: 20px;
  color: #050505;
  font-weight: bold;
}

.contenedor-cedula {
  margin: 0 auto;
}
.cedulac {
  margin: 0 auto;
  justify-content: center;
  display: flex;
}
#correoI {
  width: 480px;
}

.titunomina1,
.titunomina2,
.titunomina3 {
  background-color:  #3c6cb3;
  color: #fff;
  font-weight: bold;
  height: 40px;
}
.titunomina1 {
  text-align: center;
  width: 85px;
}
.titunomina2 {
  text-align: center;
  width: 250px;
}
.titunomina3 {
  text-align: center;
  width: 200px;
}

.cont-datoscxc {
  height: 20px;
}

.datoscxc,
.datoscxc1 {
  color: black;
  font-weight: bold;
}
.datoscxc1 {
  text-align: right;
}

.totalesnomina1 {
  height: 30px;
  text-align: left;
  font-size: 13px;
}
.totalesnomina2 {
  text-align: left;
  font-size: 13px;
}
.totalesnomina3 {
  text-align: left;
  font-size: 12px;
}

.contenedor-pago {
  height: 400px !important;
}

.flotadorPe {
  margin-top: 0%;
  position: absolute;
  height: 95%;
  width: 95%;
  align-content: center;
  justify-content: center;
  z-index: 30;
  background-color:  #3c6cb3;
  text-align: center;
}

.flotadorPe1 {
  text-align: center;
  align-self: flex-start;
  width: 100%;
}

.flotadora {
  margin-top: 2px;
  position: absolute;
  height: 95%;
  width: 95%;
  align-content: center;
  justify-content: center;
  z-index: 30;
  background-color:  #3c6cb3;
  text-align: center;
}

.flotadora1 {
  text-align: center;
  align-self: flex-start;
  width: 100%;
}
.cont-check {
  text-align: center;
}
.botonN {
  margin-top: 20px;
  margin-bottom: 5px !important;
}
.botonc {
  color: #070707;
  background-color: #fff;
  outline: none;
  border: solid 2px#C8C8C8;
  font-size: 16px;
  padding: 5px 5px;
  border-radius: 10px;
  font-weight: bold;
  margin-top: 7%;
  margin-bottom: 5%;
  margin-right: 15px;
}
.botonc,
.botonN:hover {
  color: #fff;
  background-color: rgb(35, 80, 69);
}
.botoncp {
  color: #070707;
  background-color: #c8c8c8;
  outline: none;
  border: solid 2px#C8C8C8;
  font-size: 16px;
  padding: 5px 5px;
  border-radius: 10px;
  font-weight: bold;
  /* margin-top: 7%; */
  margin-bottom: 5%;
  margin-right: 15px;
}

#descripcion {
  height: 25px !important;
  font-size: 15px !important;
  margin-top: 0px !important;
  margin-left: 15px !important;
  margin-bottom: 15px !important;
}

#tiponomina {
  margin-top: 10px;
  height: 35px;
  width: 280px !important;
  font-size: 13px;
}

#tcontrato {
  margin-top: 10px;
  height: 35px;
  width: 220px !important;
  font-size: 13px;
}
#status {
  margin-top: 10px;
  height: 35px;
  width: 150px;
  font-size: 13px;
}
.confi {
  margin-top: 10px;
}
.titulo-salario {
  color: #17cfcf;
  font-weight: bold;
  padding: 0%;
  margin: 0%;
  margin-top: 5px;
}

.titulo-salario2x {
  color:  #080a0a;
  font-weight: bold;
  padding: 0%;
  margin: 0%;
}

.titulo-asistencia {
  color: #080a0a;
  font-weight: bold;
  padding: 0%;
  margin: 0%;
}

#diasSemanal,
#salarioBaseMensual,
#horasDiaria,
#salarioHora,
#salarioDia,
#salarioQuincenal,
#ssoDecimo,
#sso,
#seguroEducativo,
#cedula,
#nombre,
#profesion,
#status,
#correo,
#cargo,
#fechaIngreso,
#fechaEgreso,
#tiponomina,
#diasTrabajado,
#saldoPrestamo,
#montoDescontarP,
#salarioB,
#montosso,
#montose,
#islr,
#subtotal,
#pagoprestamo,
#totalapagar,
#monto1,
#monto2,
#monto3,
#monto4,
#monto5,
#monto6,
#monto7,
#monto8,
#monto9,
#monto10,
#monto11,
#montosso,
#montosedu,
#montoF,
#montoT,
#montoN,
#montoP,
#decimoP {
  margin: 0;
  margin-bottom: 5px;
}

#cedulaNomina,
#quincena,
#anopagar {
  height: 30px;
  font-size: 12px;
  margin-bottom: 10px;
}

.cont-personalT {
  margin-top: 5px;
  text-align: right;
}

.cont-tablanomina {
  color: rgb(7, 5, 5);
  top: 0px;
  left: 0;
  right: 0;
  /* margin: 0 auto; */
  height: 100%;
  /* width: 100%; */
  position: absolute;
  align-content: center;
  /* justify-content: center; */
  z-index: 10;
  width: 2000px;
  margin-left: 20px;
  /* margin-top: 30px; */
}
.cont-tablanominaHx {
  color: rgb(7, 5, 5);
  top: 0px;
  left: 0;
  right: 0;
  /* margin: 0 auto; */
  height: 100%;
  /* width: 100%; */
  position: absolute;
  align-content: center;
  /* justify-content: center; */
  z-index: 10;
  width: 2000px;
  margin-left: 0px;
  margin-top: 5%;
}
.tablanomina1 {
  background-color: #8aa7cc;;
  -webkit-box-shadow: 0px 1px 1px 5px rgba(182, 184, 214, 1);
  -moz-box-shadow: 0px 1px 1px 5px rgb(182, 184, 214, 1);
  box-shadow: 0px 1px 1px 5px rgba(182, 184, 214, 1);
  align-self: flex-start;
  width: 100%;
  /* margin-top: 50px; */
  margin-bottom: 55px;
  height: 800px;
}

.titulo-nomina {
  color: #fff;
  font-size: 14px;
  font: bold;
  margin-left: 10px;
}

.xtitunomina1 {
  color: #fff;
  font-size: 14px;
  font: bold;
  text-align: center;
  width: 40px;
}
.xtitunomina2 {
  color: #fff;
  font-size: 14px;
  font: bold;
  text-align: center;
  width: 240px;
}
.xtitunomina3 {
  color: #fff;
  font-size: 14px;
  font: bold;
  text-align: center;
  width: 80px;
}

.cont-listnomi {
  color: rgb(7, 5, 5);
  top: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  border: 1px solid black;
}

.cont-listnomiHx {
  color: rgb(7, 5, 5);
  top: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
}
.listnomi {
  height: 480px !important;
  width: 100%;
  width: auto;
  overflow: auto;
}

.tablanomi {
  color: #fff;
  background-color: #7ac5cd;
  width: 100%;
  border: 1px solid #7ac5cd;
  font-size: 16px;
  border-collapse: collapse;
  /* margin: 0 0 1em 0; */
  caption-side: top;
  border-collapse: collapse;
}
.nombrePersonalx {
  font-size: 15px;
}

.nombrePersonal {
  font-size: 10px;
  color: #050505;
}
#horasE, #horasT{
  font-size: 12px;
  width: 40px;
  height: 35px;
  font: bold;
}

.theadcreartabla .trcreartabla  { 
  position: sticky;
  top: 0;
  z-index: 10;
  background-color:  #1b538a;
}

.table-responsive { 
  height:200px;
  overflow:scroll;
} 

.odd1{
  background-color: #34dbc2;
}
.odd2{
  background-color: #e6a66a;
}
.odd3{
  background-color: #5b2feb;
}
.odd4{
  background-color: #e787c2;
}
.odd5{
  background-color: #5379b8;
}
.odd6{
  background-color: #c2d364;
}
.odd7{
  background-color: #5a995a;
}
.odd8{
  background-color: #e0928d;
}
.odd9{
  background-color: #eba31f;
}
.odd10{
  background-color: #8fcaf1;
}

.cargoo{
  font-size: 8px;
  color: #050505;
}

.contenedorcedulaNomi{
  color: #050505;
}

.pagado{
  color: red;
  font-size: 20px;
}

.cont-titulomedix{
  text-align: center;
  margin-top: 15px;
}
#lunesCheck, #martesCheck, #miercolesCheck, #juevesCheck, #viernesCheck, #sabadoCheck{
  margin-top: 20px;
  width: 100px;
}
.tituloofertaH {
  color: #fff;
  font-size: 18px;
  width: 140px;
}
.cont-dias{
  border: 1px solid black;
}