.cerrarme4{
    background-color: #6aaeec;
    background-image: url('../../imagenes/editor.png');
    background-size: 20px;
    background-repeat: no-repeat; 
    width: 25px;
    height: 25px;
    background-position:center;
    font-size: 14px;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block;
    margin-top: 2px;
  }
  .cerrarme5{
    background-color: #6aaeec;
    background-image: url('../../imagenes/editor.png');
    background-size: 20px;
    background-repeat: no-repeat; 
    width: 25px;
    height: 25px;
    background-position:center;
    font-size: 14px;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block;
    cursor: pointer;
    margin-top: 2px;
  }
  .opcionno{
      margin-top: 10px;
      width: 100px;
  }
  #ordendellegada{
      height: 25px;
      width: 50px;
      margin: 0;
      padding: 0;
      text-align: left;    
  }
  .cont-fechano {
        margin-top: 10px;
        margin: 0;
}
  #fechaesperano{
      height: 25px;
      margin:0;
  }
  .cont-motivo-no{
      text-align: left;
  }

  .cont-listmedix {
        color: rgb(167, 167, 167);
        top: 40px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 80% !important;
        justify-content: center;
  }
  

  .listcxcx {
    background-color: #d2ddeb;
    height: 240px;
    width: auto;
    overflow: auto;
  }

  .tablacxcx{
    color: #fff;
    background-color: #fff;
    width: 100%;
    border: 1px solid #7ac5cd;
    font-size: 16px;
    border-collapse: collapse;
    /* margin: 0 0 1em 0; */
    caption-side: top;
    border-collapse: collapse;  
    height: 20px;
}
  .xcontenedorcedula1x {
    text-align: center;
    display: inline-block;
    margin-top: 10px !important;

  }
  .titucxc1{
    width: 325px !important;
  }
  .titucxc3x{
      width: 5px !important;
      text-align: center !important;
  }
.totalescxcx {
    cursor: context-menu !important;
    text-align: left !important;
    font-size: 12px !important;
}
.contenedorcedula1x {
    width: 700px !important;
}

.diasH{
  width: 70px;
  height: 40px;
  background-color: #6aaeec;
  text-align: center;
  font-size: 10px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f0f0f0;
  font-weight: bold;
  /* position: fixed;
  left: 36vw; */
}
.diasHT1{
  width: 55px;
  height: 40px;
  background-color: #6aaeec;
  text-align: center;
  font-size: 10px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f0f0f0;
  font-weight: bold;
   position: relative;
   float: left;
   margin-left: 15px;
  /* left: 5px; */
}
.contenedorpadre{
  position: fixed;
}
.diasS{
  width: 143px;
  height: 40px;
  background-color: #6aaeec;
  text-align: center;
  font-size: 15px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f0f0f0;
  font-weight: bold;
}

.diasST1{
  width: 143px;
  height: 40px;
  background-color: #6aaeec;
  text-align: center;
  font-size: 15px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f0f0f0;
  font-weight: bold;
  position: relative; 
  float: left;
  
  /* left: 3.3vw; */
  
}
.diasST2{
  width: 143px;
  height: 40px;
  background-color: #6aaeec;
  text-align: center;
  font-size: 15px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f0f0f0;
  font-weight: bold;
  position: relative; 
  float: left;
  
}
.diasST3{
  width: 143px;
  height: 40px;
  background-color: #6aaeec;
  text-align: center;
  font-size: 15px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f0f0f0;
  font-weight: bold;
  position: relative; 
  float: left;
  
}
.diasST4{
  width: 143px;
  height: 40px;
  background-color: #6aaeec;
  text-align: center;
  font-size: 15px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f0f0f0;
  font-weight: bold;
  position: relative; 
  float: left;
  
}
.diasST5{
  width: 143px;
  height: 40px;
  background-color: #6aaeec;
  text-align: center;
  font-size: 15px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f0f0f0;
  font-weight: bold;
  position: relative; 
  float: left;
  
}
.diasST6{
  width: 143px;
  height: 40px;
  background-color: #6aaeec;
  text-align: center;
  font-size: 15px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f0f0f0;
  font-weight: bold;
  position: relative; 
  float: left;
}
.diasSD{
  width: 56.6%;
  height: 40px;
  background-color: #6aaeec;
  text-align: center;
  font-size: 15px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f0f0f0;
  font-weight: bold;
  position: fixed;
  left: 39.78vw;
}

.XdiasH{
  width: 70px;
  text-align: center;
  font-size: 10px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  background-color: #d2ddeb;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #070707;
  font-weight: bold;
}
.XdiasHl{
  width: 70px;
  text-align: center;
  font-size: 10px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  color: red;
  background-color:#90c7ec;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.XdiasS{
  width: 143px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  background-color: #d2ddeb;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#070707;
  font-weight: bold;
}
.counterx {
  width: 143px;
  height: 40px;
  margin: auto;
  font-size: 15px;
  color: #bd1de6;
  text-align: right;
  /* background-color: red; */
  /* display: inline; */
}
.counterx2 {
  width: 143px;
  height: 40px;
  margin: auto;
  font-size: 12px;
  /* background-color: rgb(0, 255, 106); */
  /* display: inline; */
}

.XdiasSD{
  width: 90%;
  height: 40px;
  text-align: center;
  font-size: 10px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  background-color: #d2ddeb;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0a06dd;
  font-weight: bold;
}
.XdiasSDC{
  width: 90%;
  height: 40px;
  text-align: center;
  font-size: 10px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  background-color: #d2ddeb;
  /* background-color: #0f6fec; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0a06dd;
  font-weight: bold;
  display: inline;
}
.XdiasSDC2{
  width: 143px;
  height: 90px;
  text-align: center;
  font-size: 10px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  background-color: #d2ddeb;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0a06dd;
  font-weight: bold;
  display: inline;
  /* white-space: break-word; */

}
.counter {
  width: 10%;
  /* height: 30%; */
  margin: auto;
  font-size: 15px;
  color: #bd1de6;
  text-align: left;
  /* background-color: red; */
  /* display: inline; */
}
.counter2 {
  width: 100%;
  height: 50%;
  margin: auto;
  font-size: 10px;
  /* background-color: rgb(0, 255, 106); */
  /* display: inline; */
}


.XdiasSl{
  width: 143px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  color: red;
  background-color: #90c7ec;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.XdiasSlD{
  width: 90%;
  height: 40px;
  text-align: center;
  font-size: 20px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  color: red;
  background-color: #90c7ec;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.cont-diasterapia{
  background-color: #d2ddeb;
}
.diasNombre{
  background-color: #d2ddeb;
}
.diasHt{
  width: 130px;
  background-color: #6aaeec;
  text-align: center;
  font-size: 12px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
}
.diasSt{
  width: 100px;
  background-color: #6aaeec;
  text-align: center;
  font-size: 12px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
}
.XdiasHt{
  width: 130px;
  text-align: center;
  font-size: 10px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  background-color: #d2ddeb;
}
.XdiasSt{
  width: 100px;
  text-align: center;
  font-size: 12px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  background-color: #d2ddeb;
}
.diasEsp{
  width: 130px;
  background-color: #6aaeec;
  text-align: center;
  font-size: 12px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
}
.XdiasEsp{
  width: 130px;
  text-align: center;
  font-size: 10px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  background-color: #d2ddeb;
}
.cont-diasterapia2{
  background-color: #d2ddeb;
  margin-top: 50px;
  
}

.react-calendar { 
  width: 250px;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 14px;
  margin-bottom: 0px;
  
 }


 .react-calendar__navigation button {
  color: #6f48eb;
  min-width: 44px;
  background: none;
  font-size: 10.5px;
  margin-top: 8px;
 }
 .react-calendar__navigation button:enabled:hover,
 .react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
 }
 .react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
 }
 abbr[title] {
  text-decoration: none;
 }
 /* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
 } */
 .react-calendar__tile:enabled:hover,
 .react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 6px;
  
 }
 .react-calendar__tile--now {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #6f48eb;
  
 }
 .react-calendar__tile--now:enabled:hover,
 .react-calendar__tile--now:enabled:focus {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #6f48eb;
  
 }
 .react-calendar__tile--hasActive:enabled:hover,
 .react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
  
 }
 .react-calendar__tile--active {
  background: #6f48eb;
  border-radius: 6px;
  font-weight: bold;
  color: white;
  
 }
 .react-calendar__tile--active:enabled:hover,
 .react-calendar__tile--active:enabled:focus {
  background: #6f48eb;
  color: white;
 }
 .react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
 }
 .react-calendar__tile--range {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 0;
  
 }
 .react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #6f48eb;
  color: white;
  
 }
 .react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #6f48eb;
  color: white;
  
 }

.react-calendar__month-view__weekdays {
  text-align: center;
  border: 1px solid #b2a9b5 !important;
  border-radius: 5px;
  margin-bottom: 2px !important;
  font-size: 8px;
}
.este {
  height: 25em;
  line-height: 1em;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  border: 1px solid;
}
.cont-contenedor{
  padding: 0px !important;
}
.mostrarFechas {
  font-size: 20px;
  color: #bd1de6;
}
.cont-forma{
  margin-top: 5px !important;
  background-color: aliceblue
}
/* .cont-Boton {
  margin-top: 10px;
  padding-left: 20px;
} */
.botonff1{
  color: #070707;
  background-color: #fff;
  outline: none;
  border: solid 2px#C8C8C8;
  font-size: 15px !important;
  padding: 8px 8px;
  border-radius: 10px; 
  font-weight: bold;
  margin-bottom: 5%;
  margin-right: 10px;
}
.botonff2:hover {
  color:  #fff;
  background-color: #3b76c4;
  }
.botonff2 {
  color: #070707;
  background-color: #fff;
  outline: none;
  border: solid 2px#C8C8C8;
  font-size: 15px !important;
  padding: 8px 8px;
  border-radius: 10px; 
  font-weight: bold;
  margin-bottom: 5%;
}
.botonff1:hover {
color:  #fff; 
background-color: #3b76c4;
}
.dispo1{
  color: rgb(23, 228, 149);
  font-weight: bold;
}
.contenedor-casos {
margin-top: 20px;  
}
.caja-header{
  width: 200px;
  height: 50px;
  background-color: #b2a9b5;
  margin: 10px;
}
.caja-contenido{
  width: 200px;
  height: 50px;
  background-color: #d6a4e7;
  margin-bottom: 30px;
  margin: 10px;
}
.cont-img{
  text-align: left;
}
.ayuda, .help-circle{
  color: #050505;
  font-weight: bold;
  margin-right: 5px;
  zoom: 70%;
}
.enconsulta, .check{
  color: #5411ee;
  font-weight: bold;
  margin-right: 5px;
  zoom: 70%;
}
.dolar, .dollar-sign{
  color:  #050505;
  font-weight: bold;
  margin-right: 5px;
  zoom: 70%;
}
.cancelado, .x{
  color: #ee1628;
  font-weight: bold;
  margin-right: 5px;
  zoom: 70%;
}
.noasistio, .x-circle{
  color: #ee1628;
  font-weight: bold;
  margin-right: 5px;
  zoom: 70%;
}
.espera, .clock{
  color: #050505;
  font-weight: bold;
  margin-right: 5px;
  zoom: 70%;
}
.confirmado, .edit-3{
  color: #050505;
  font-weight: bold;
  margin-right: 5px;
  zoom: 70%;
}
.nocobrado, .user-minus{
  color: #ee1628;
  font-weight: bold;
  margin-right: 5px;
  zoom: 70%;
}
.donacion, .user-check{
  color: #050505;
  font-weight: bold;
  margin-right: 5px;
  zoom: 70%;
}
.cancelado-2, .x-square{
  color: #ee1628;
  font-weight: bold;
  margin-right: 5px;
  zoom: 70%;
}
.cambio, .refresh-cw{
  color: #ee1628;
  font-weight: bold;
  margin-right: 5px;
  zoom: 70%;
}
.chevron{
  color: #ee1628;
  height: 30px;
  font-weight: bold;
  margin-right: 0px;
  zoom: 90%;
}

.fecha2{
  font-size: 20px;
  margin-right: 20px;
  color: floralwhite;
  font-weight: bold;
}
#fechaT{
  width: 200px;
  font-size: 20px;
}
.ayuda-1{
  color: #ee1628;
  font-weight: bold;
  margin-right: 5px;
  zoom: 120%;
}
.enconsulta-1{
  color: #ee1628;
  font-weight: bold;
  margin-right: 5px;
  zoom: 120%;
}
.chevron-1{
  color: #ee1628;
  font-weight: bold;
  margin-right: 5px;
  zoom: 120%;
}
.dolar-1{
  color: #ee1628;
  font-weight: bold;
  margin-right: 5px;
  zoom: 120%;
}
.cancelado-1{
  color: #ee1628;
  font-weight: bold;
  margin-right: 5px;
  zoom: 120%;
}
.noasistio-1{
  color: #ee1628;
  font-weight: bold;
  margin-right: 5px;
  zoom: 120%;
}
.espera-1{
  color: #ee1628;
  font-weight: bold;
  margin-right: 5px;
  zoom: 120%;
}
.confirmado-1{
  color: #ee1628;
  font-weight: bold;
  margin-right: 5px;
  zoom: 120%;
}
.nocobrado-1{
  color: #ee1628;
  font-weight: bold;
  margin-right: 5px;
  zoom: 120%;
}
.donacion-1{
  color: #ee1628;
  font-weight: bold;
  margin-right: 5px;
  zoom: 120%;
}
.cancelado-3{
  color: #ee1628;
  font-weight: bold;
  margin-right: 5px;
  zoom: 120%;
}
.cambio-1{
  color: #ee1628;
  font-weight: bold;
  margin-right: 5px;
  zoom: 120%;
}
.lupa{
  color: #ee1628;
  font-weight: bold;
  margin-left: 10px;
  zoom: 120%;
}
.contenedor-cxcP {
  width: 100%;
  height: 620px;
  background-color: #d2ddeb;
}

.texto-img{
  font-size: 13px;
  margin-right: 20px;
  color: rgb(245, 29, 29);
  font-weight: bold;
}
.cont-tipoD{
  font-size: 10px;
  margin-top: 10px;
  color: rgb(1, 3, 5);
  font-weight: bold;
  text-align: justify;
}

#div1{
  margin-top: 10%;
  /* background-color: black; */
  width: 100%;
  /* color: white; */
}
#div2{
  /* background-color: red; */
  width: 70%;
  float: left;
  position: relative;     
}
#div3{
  /* background-color: green; */

  width: 30%;
  float: right;
  position: relative;

}
.parrafo{
height: 5px ;
}
.calender{
  max-width: 24%;
}

.dato1{
  /* width: 143px;
  height: 90px; */
  text-align: center;
  font-size: 8.5px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  background-color: #5aeb72;
  display: flex;
  /* justify-content: center;
  align-items: center;
  color: #0a06dd;
  font-weight: bold;
  display: inline; */
 

}
.dato2{
  /* width: 143px;
  height: 90px; */
  text-align: center;
  font-size: 8.5px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  background-color: #d9e28a;
  display: flex;
  /* justify-content: center;
  align-items: center;
  color: #0a06dd;
  font-weight: bold;
  display: inline; */
  

}
.dato3{
  /* width: 143px;
  height: 90px; */
  text-align: center;
  font-size: 8.5px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  background-color: #df6db9;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0a06dd;
  font-weight: bold;
  display: inline;
  /* white-space: break-word; */

}